<template>
    <div class="modal-edit-dating-preference">
        <div class="content m-b-28">
            <span class="m-r-12"
                ><p class="f-bold">소개팅 & 연락,</p>
                어떻게 하는 편이세요?</span
            >
            <div class="emoji">💓</div>
        </div>
        <button class="btn btn-primary" @click="goToEditDatingStylePage">소개팅 스타일 입력</button>
    </div>
</template>

<script>
export default {
    name: 'ModalEditDatingPreference',
    methods: {
        goToEditDatingStylePage() {
            this.$emit('close')
            this.$stackRouter.push({
                name: 'EditDatingPreferencePage',
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-edit-dating-preference {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 208px;
    border-radius: 12px 12px 0 0;
    padding: 24px;

    .content {
        height: 84px;
        color: black;
        font-size: 24px;
        letter-spacing: -0.4px;
        line-height: 1.58;

        @include flex-row;
        @include flex-between;
        @include items-center;
        @include f-regular;

        .emoji {
            font-family: AppleColorEmoji;
            font-size: 64px;
        }

        @media (max-width: 342px) {
            .emoji {
                display: none;
            }
        }
    }

    button {
        width: 100%;
        height: 48px;
        letter-spacing: -0.2px;
    }
}
</style>
